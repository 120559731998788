import {useMemo} from 'react';
import {Card, CardBody} from 'reactstrap';

import {FormikDateInput, FormikInput, FormikSelect} from '@reasoncorp/kyber-js';

import {ReviewSheetQuestion} from '../reviewSheet';

type Props = {
  renderCondition: boolean
  radioFieldName: string
  radioLabelText: string
  radioButtons?: {value: string, labelText: string}[]
  notesFieldName: string
  resolutions?: string[]
  resolutionFieldName?: string
  resolutionDateFieldName: string
  resolutionCommentsFieldName: string
  disabled: boolean
}

const CapResolution = ({
                         renderCondition,
                         radioFieldName,
                         radioLabelText,
                         radioButtons = [
                           {value: 'Yes', labelText: 'Yes'},
                           {value: 'No', labelText: 'No'}
                         ],
                         notesFieldName,
                         resolutions,
                         resolutionFieldName,
                         resolutionDateFieldName,
                         resolutionCommentsFieldName,
                         disabled
                       }: Props) => {
  const renderOption = useMemo(() => (resolution: string, index: number) => {
    return <option key={index}
                   value={resolution}>
      {resolution}
    </option>;
  }, []);

  // Only render if the render condition is true.
  if (renderCondition) {
    return (
      <Card className="mb-3">
        <CardBody>
          <ReviewSheetQuestion radioFieldName={radioFieldName}
                               radioLabelText={radioLabelText}
                               radioButtons={radioButtons}
                               notesFieldName={notesFieldName}
                               notesLabelText="Audit Notes"
                               notesAriaLabel="Audit Notes"
                               formEditable={false}/>
          {resolutions && resolutionFieldName &&
            <FormikSelect name={resolutionFieldName}
                          labelText="Resolution"
                          ariaLabel="Resolution"
                          disabled={disabled}>
              <option value="">Select</option>
              {resolutions.map(renderOption)}
            </FormikSelect>
          }
          <FormikDateInput name={resolutionDateFieldName}
                           labelText="Resolution Date"
                           aria-label="Resolution Date mm/dd/yyyy"
                           formGroupClass="larger-label"
                           className="w-auto"
                           disabled={disabled}/>
          <FormikInput name={resolutionCommentsFieldName}
                       labelText="Resolution Comments"
                       type="textarea"
                       formGroupClass="larger-label"
                       maxLength={400}
                       aria-label="Resolution Comments"
                       disabled={disabled}/>
        </CardBody>
      </Card>
    );
  } else {
    return null;
  }
};

export default CapResolution;