import {Col, Row} from 'reactstrap';
import {useFormikContext} from 'formik';

import {FormikDateInput, FormikInput, useUserContext} from '@reasoncorp/kyber-js';

import {LocalUnitAudit, LocalUnitContactInfo} from '../../types';
import {ReviewSheetV2} from '../../types/reviewSheet';
import {reviewSheetV2Questions} from '../../schema/reviewSheet';
import ReviewSheetComments from './ReviewSheetComments';
import ReviewSheetQuestion from './ReviewSheetQuestion';
import ReviewSheetSignature from './ReviewSheetSignature';
import LocalUnitBackgroundCard from './LocalUnitBackgroundCard';
import {AssessmentRollAnalysisCard, PersonalPropertyReviewCard, ReviewOfExemptionsCard} from '../shared';

type Props = {
  showContactInfo?: boolean
  formEditable: boolean
  localUnitAudit: LocalUnitAudit
  localUnitContactInfo?: LocalUnitContactInfo
}

const ReviewSheetV2Form = ({
                             showContactInfo = false,
                             formEditable,
                             localUnitAudit,
                             localUnitContactInfo
                           }: Props) => {
  const formikContext = useFormikContext<ReviewSheetV2>();
  const {permissions} = useUserContext();

  return (
    <>
      <LocalUnitBackgroundCard className="mb-4"
                               showContactInfo={showContactInfo}
                               localUnitAudit={localUnitAudit}
                               localUnitContactInfo={localUnitContactInfo}>
        <Row>
          <Col>
            <FormikDateInput name="localUnitBackground.assessmentRollCertificationDate"
                              labelText={reviewSheetV2Questions.localUnitBackground.assessmentRollCertificationDate}
                              aria-label={`${reviewSheetV2Questions.localUnitBackground.assessmentRollCertificationDate} mm/dd/yyyy`}
                              formGroupClass="larger-label"
                              className="w-auto"
                              disabled={!formEditable}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikInput name="localUnitBackground.cod"
                         labelText={reviewSheetV2Questions.localUnitBackground.cod}
                         className="text-right w-auto"
                         formGroupClass="larger-label"
                         aria-label="Coefficient of Dispersion"
                         disableFloatingLabel={true}
                         disabled={!formEditable}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikInput name="localUnitBackground.codNotes"
                         labelText="Notes"
                         formGroupClass="larger-label"
                         aria-label="Coefficient of Dispersion Notes"
                         type="textarea"
                         maxLength="250"
                         disabled={!formEditable}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikInput name="localUnitBackground.prd"
                         labelText={reviewSheetV2Questions.localUnitBackground.prd}
                         className="text-right w-auto"
                         formGroupClass="larger-label"
                         aria-label="Price Related Differential"
                         disableFloatingLabel={true}
                         disabled={!formEditable}/>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormikInput name="localUnitBackground.prdNotes"
                         labelText="Notes"
                         formGroupClass="larger-label"
                         aria-label="Price Related Differential Notes"
                         type="textarea"
                         maxLength="250"
                         disabled={!formEditable}/>
          </Col>
        </Row>
        <ReviewSheetQuestion radioFieldName="localUnitBackground.l4022Matches"
                             radioLabelText={reviewSheetV2Questions.localUnitBackground.l4022Matches}
                             notesFieldName="localUnitBackground.l4022MatchesNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="L4022 notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="localUnitBackground.form4142Completed"
                             radioLabelText={reviewSheetV2Questions.localUnitBackground.form4142Completed}
                             notesFieldName="localUnitBackground.form4142CompletedNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Form 4142 Completed Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="localUnitBackground.writtenProcedures"
                             radioLabelText={reviewSheetV2Questions.localUnitBackground.writtenProcedures}
                             notesFieldName="localUnitBackground.writtenProceduresNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Written Procedures Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="localUnitBackground.landValueMapsAccurate"
                             radioLabelText={reviewSheetV2Questions.localUnitBackground.landValueMapsAccurate}
                             notesFieldName="localUnitBackground.landValueMapsAccurateNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Land Value Maps Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
      </LocalUnitBackgroundCard>
      <AssessmentRollAnalysisCard className="mb-4">
        <ReviewSheetQuestion radioFieldName="assessmentRollAnalysis.economicConditionFactorsMet"
                             radioLabelText={reviewSheetV2Questions.assessmentRollAnalysis.economicConditionFactorsMet}
                             notesFieldName="assessmentRollAnalysis.economicConditionFactorsMetNotes"
                             notesLabelText="Notes"
                             notesMaxLength={500}
                             notesAriaLabel="Economic Condition Factors Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriate"
                             radioLabelText={reviewSheetV2Questions.assessmentRollAnalysis.landValueDeterminationsAppropriate}
                             notesFieldName="assessmentRollAnalysis.landValueDeterminationsAppropriateNotes"
                             notesLabelText="Notes"
                             notesMaxLength={500}
                             notesAriaLabel="Land Value Determinations Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="assessmentRollAnalysis.cashValueAgrees"
                             radioLabelText={reviewSheetV2Questions.assessmentRollAnalysis.cashValueAgrees}
                             notesFieldName="assessmentRollAnalysis.cashValueAgreesNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Cash Value Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
      </AssessmentRollAnalysisCard>
      <PersonalPropertyReviewCard className="mb-4">
        <ReviewSheetQuestion radioFieldName="personalPropertyReview.conductsAnnualPersonalPropertyCanvas"
                             radioLabelText={reviewSheetV2Questions.personalPropertyReview.conductsAnnualPersonalPropertyCanvas}
                             notesFieldName="personalPropertyReview.conductsAnnualPersonalPropertyCanvasNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Annual Personal Property Canvass Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="personalPropertyReview.smallBusinessExemptionsGranted"
                             radioLabelText={reviewSheetV2Questions.personalPropertyReview.smallBusinessExemptionsGranted}
                             notesFieldName="personalPropertyReview.smallBusinessExemptionsGrantedNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Small Business Taxpayer Exemption Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        {formikContext.values.personalPropertyReview.smallBusinessExemptionsGranted === 'Yes' &&
          <ReviewSheetQuestion radioFieldName="personalPropertyReview.exemptionsProcessingRequirementMet"
                               radioLabelText={reviewSheetV2Questions.personalPropertyReview.exemptionsProcessingRequirementMet}
                               notesFieldName="personalPropertyReview.exemptionsProcessingRequirementMetNotes"
                               notesLabelText="Notes"
                               notesMaxLength={250}
                               notesAriaLabel="Exemptions Processing Requirements Notes"
                               formEditable={formEditable}
                               isFollowUpAudit={localUnitAudit.followUp}
                               previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        }
      </PersonalPropertyReviewCard>
      <ReviewOfExemptionsCard className="mb-4">
        <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.povertyExemptionsGranted"
                             radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.povertyExemptionsGranted}
                             notesFieldName="reviewOfExemptionsGranted.povertyExemptionsGrantedNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Poverty Exemption Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.hasPovertyExemptionGuidelines"
                             radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.hasPovertyExemptionGuidelines}
                             notesFieldName="reviewOfExemptionsGranted.hasPovertyExemptionGuidelinesNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Poverty Exemption Guidelines Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTest"
                             radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTest}
                             notesFieldName="reviewOfExemptionsGranted.guidelinesIncludeAssetLevelTestNotes"
                             notesLabelText="Notes"
                             notesMaxLength={250}
                             notesAriaLabel="Guidelines Asset Level Test Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        {formikContext.values.reviewOfExemptionsGranted.povertyExemptionsGranted === 'Yes' &&
          <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowed"
                               radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowed}
                               notesFieldName="reviewOfExemptionsGranted.statutoryRequirementsMetAndPolicyFollowedNotes"
                               notesLabelText="Notes"
                               notesMaxLength={250}
                               notesAriaLabel="Statutory Requirements and Policy Notes"
                               formEditable={formEditable}
                               isFollowUpAudit={localUnitAudit.followUp}
                               previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        }
        <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsidered"
                             radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsidered}
                             radioButtons={[
                               {value: 'Yes', labelText: 'Yes'},
                               {value: 'No', labelText: 'No'},
                               {value: 'N/A', labelText: 'N/A'}
                             ]}
                             notesFieldName="reviewOfExemptionsGranted.onlyStatutoryAuthorityItemsConsideredNotes"
                             notesLabelText="Notes"
                             notesMaxLength={500}
                             notesAriaLabel="Statutory Authority Items Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.ptaFilingImposed"
                             radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.ptaFilingImposed}
                             notesFieldName="reviewOfExemptionsGranted.ptaFilingImposedNotes"
                             notesLabelText="Notes"
                             notesMaxLength={500}
                             notesAriaLabel="Property Transfer Affidavit Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        {formikContext.values.reviewOfExemptionsGranted.ptaFilingImposed === 'No' &&
          <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.penaltyWaivedByResolution"
                               radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.penaltyWaivedByResolution}
                               notesFieldName="reviewOfExemptionsGranted.penaltyWaivedByResolutionNotes"
                               notesLabelText="Notes"
                               notesMaxLength={250}
                               notesAriaLabel="If Property Transfer Affidavit Waived Notes"
                               formEditable={formEditable}
                               isFollowUpAudit={localUnitAudit.followUp}
                               previousReviewSheet={localUnitAudit.previousReviewSheet}/>
        }
        <ReviewSheetQuestion radioFieldName="reviewOfExemptionsGranted.implementedCamaDataStandards"
                             radioLabelText={reviewSheetV2Questions.reviewOfExemptionsGranted.implementedCamaDataStandards}
                             notesFieldName="reviewOfExemptionsGranted.implementedCamaDataStandardsNotes"
                             notesLabelText="Notes"
                             notesMaxLength={500}
                             notesAriaLabel="CAMA Data Standards Notes"
                             formEditable={formEditable}
                             isFollowUpAudit={localUnitAudit.followUp}
                             previousReviewSheet={localUnitAudit.previousReviewSheet}/>
      </ReviewOfExemptionsCard>
      <ReviewSheetComments formEditable={formEditable}
                           permissions={permissions}/>
      <ReviewSheetSignature formEditable={formEditable}
                            permissions={permissions}/>
    </>
  );
};

export default ReviewSheetV2Form;